import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Product from "../components/product"

const ShopPageStyles = styled.div`
  h1 {
    margin: 50px 0;
    width: 100%;
    text-align: center;
    font-family: "prompt", Arial, Helvetica, sans-serif;
  }

  .products-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 3em 1em;
    max-width: 1350px;
    margin: 0 auto;
  }

  @media (max-width: 800px) {
    article {
      margin: 0 auto 1em;
    }
  }
`

export default function ShopPage({ data, location }) {
  const siteTitle = "Shop"
  const pageData = data.allShopifyProduct.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={siteTitle}
        keywords={[
          `carbon wheels`,
          `carbon fiber wheels`,
          `carbon bicycle wheels`,
          `high performance bicycle wheels`,
          `bike wheels`,
          `bmx cruiser wheels`,
          `29er bmx`,
          `fixed gear wheels`,
          `fixed wheels`,
          `fixie wheels`,
          `track wheels`,
          `road bike wheels`,
          `carbon wheelset`,
          `usa made wheels`,
        ]}
      />
      <ShopPageStyles>
        <h1>Shop Encore Wheels</h1>
        <div className="products-container">
          {pageData.map((edge) => (
            <Product product={edge.node} key={edge.node.id} />
          ))}
        </div>
      </ShopPageStyles>
    </Layout>
  )
}

export const query = graphql`
  query ShopPageQuery {
    allShopifyProduct(filter: { vendor: { in: ["Encore Wheels", "All"] } }) {
      edges {
        node {
          id
          handle
          title
          description
          descriptionHtml
          variants {
            sku
            id
            title
            price
          }
          media {
            preview {
              image {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          priceRangeV2 {
            minVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`
